var $name="shell/engine/appendSDLParameters",$path="app/modules/shell/engine/appendSDLParameters.js",$this={$name,$path,};const appendSDLParameters = () => {
  const SDLUrls = ['products/industrial-automation-control/tools/modicon-plc-configurator.jsp',
    'products/industrial-automation-control/tools/software-license-configurator.jsp'];
  const SDLParams = ['plcSearch', 'searchType', 'main', 'technology', 'range_id', 'reference',
    'ignoreParametersRedirection', 'campaignId', 'configurationId', 'configuration', 'technology', 'campaignId'];
  const SDLLocalParams = ['routing', 'json'];
  // Get the current URL
  // eslint-disable-next-line max-len
  const currentURL = window.location.href;

  // Check if the current URL contains any of the URLs from SDLUrls
  const containsSDLURL = SDLUrls.some((url) => currentURL.includes(url));
  const url = new URL(currentURL);
  const params = new URLSearchParams(url.search);

  if (containsSDLURL) {
    // If the current URL does not contain any of the URLs from SDLUrls, restrict the parameters
    for (const param of params.keys()) {
      if (!SDLParams.includes(param)) {
        url.searchParams.delete(param); // Remove the parameter not present in SDLParams
      }
    }
  } else {
    for (const param of params.keys()) {
      if (!SDLLocalParams.includes(param)) {
        url.searchParams.delete(param); // Remove the parameter not present in SDLParams
      }
    }
  }
  history.replaceState(null, '', url.href);
};

export default appendSDLParameters;
